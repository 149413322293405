// There are some major variables below,
// all less variables could be found in
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

// font
@font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// base variables
@border-radius-base: 6px;

// colors
@primary-color: #67be23;
@error-color: #fa541c;
@text-color: #626262;
@icons-color: #ffffff;
@dark-icons-color: #2a132e;

// sider
@layout-sider-background: #2a132e;
@menu-bg: transparent;
@menu-item-color: #fff;
@menu-highlight-color: #fff;
@menu-inline-submenu-bg: transparent;
@menu-item-active-bg: transparent;
@menu-item-active-border-width: 0px;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

//form
@label-color: @text-color;

.ant-menu-light, .ant-menu-light>.ant-menu {
  background: transparent!important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}

.ant-select-dropdown {
  min-width: 120px!important;
}

.ant-drawer-inline {
  position: fixed;
}

.ant-form-item-label > label {
  font-weight: bold;
}

.ant-page-header {
  border-radius: @border-radius-base;
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #2a132e;
}
.ant-menu-tree-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #fff;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-tree-arrow::before,
.ant-menu-tree-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}
.ant-menu-tree-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-tree-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-dropdown-menu-title-content {
  font-size: 14px;
  font-weight: 400;
}

.action-page-layout .ant-descriptions-header {
  margin-bottom: 8px;
}

.action-page-layout .ant-descriptions-title {
  color: #626262;
  font-size: 14px;
  height: auto;
}


.ant-page-header.action-page-layout {
  background: transparent;
}

.action-page-layout .ant-descriptions-small .ant-descriptions-row > th, .ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 0;
}

.action-page-layout .ant-descriptions-small .ant-descriptions-row > th span,
.action-page-layout .ant-descriptions-small .ant-descriptions-row > td span {
  width: 100%;
}

.action-page-layout .ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: fixed;
}

.action-page-layout .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.action-page-layout .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 10px 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.width-100-pr {
  width: 100%;
}

.card-block-with-margin {
  width: 100%;
  margin-bottom: 16px;
}

.row-with-margin-12 {
  margin-bottom: 12px;
}

.block-without-margin {
  margin-bottom: 0;
}

.filter-block-without-margin {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .filter-block-without-margin {
    margin-bottom: 18px;
  }
}


// table buttons
.action-table-button {
  border: none;
  background-color: transparent;
  padding: 0!important;
  box-shadow: none;
}
.action-table-button .anticon {
  color: @primary-color;
}
.action-table-button.ant-btn-dangerous  .anticon {
  color: @error-color;
}
.action-table-button.ant-btn:hover {
  background-color: transparent;
}

.required-group-header::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

// menu and icons

.table-menu-item {
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.table-menu-item .menu-item-icon {
  color: @primary-color;
  font-size: 15px;
  font-weight: 500;
}

.table-menu-item .menu-item-icon.delete-icon {
  color: @error-color;
}

.table-dropdown-icon {
  font-size: 24px;
}

.table-menu-item a {
  font-size: 14px;
  font-weight: 400;
}


.readonly-delivery-options-wrapper .ant-btn-primary:hover, .readonly-delivery-options-wrapper .ant-btn-primary:focus {
  color: #fff;
  border-color: @primary-color;
  background: @primary-color;
}

.preview-entity-title .ant-image-mask-info {
  width: 22px;
}


// dashboard
.ant-progress-circle.ant-progress-status-success .ant-progress-text, .ant-progress-circle .ant-progress-text {
  color: rgba(190,190,190,0.59)
}

// user support
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 16px;
  height: 56px;
  font-size: 16px;
  font-weight: 600;
}

.chat-scroll {
  height: 550px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  margin-top: 20px;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.chat-inner {
  margin-bottom: 60px;
}

.chat-message {
  max-width: 256px; 
  width: fit-content;
  padding: 3px 12px;
  border-radius: 12px;
}

.chat-filters {
 height: 56px;
 display: flex;
 gap: 12px;
 padding: 12px 21px;
}

.chats-user {
  max-width: 471px;
  width: 100%;
  height: 72px;
  padding: 12px 16px;
  cursor: pointer;
}

.chats-user-block {
  display: flex;
  gap: 16px;
}

.chats-user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 375px;
  width: 100%;
}

.chat-header-info {
  display: flex;
  gap: 16px;
  align-items: center;
}

.chats-user-top {
  max-width: 375px;
  width: 100%;
}

.chats-user-avatar {
  border-radius: 50%;
}

.chats-user-nickname {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
}

.chats-user-message {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  max-width: 300px;

  @media only screen and (max-width: 1287px) {
    max-width: 140px;
  }
}

.chats-user-message-unread {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #1568DB;
}

.chats-user-time {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.chats-button-opened {
  color: #1568DB; 
  border: 1px solid #1568DB;
  padding: 0 32px; 
}

.chats-button-all {
  border: 1px solid #141414;
  padding: 0 32px;

  &:hover {
    opacity: 0.8;
  }
}

.chats-button-all:where(.css-dev-only-do-not-override-bhzqiq).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
 color: #fff
}

.chats-users {
  height: 675px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.chat-form {
  height: 105px;
  position: relative;
}

.chat-container  {
  display: flex;
  height: 105px;
  max-width: 742px;
}

.chat-container p {
  flex-basis: 100%;
  overflow: hidden;
  max-height: 100%;
  margin: 0;
  font-size: 16px;
  font-weight: 400px;
  line-height: 20px;
  align-self: flex-end;
  padding: 16px 72px 16px 16px;
  overflow-y: auto;
  outline: #282828;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.chat-container-icon {
  cursor: pointer;
  position: absolute;
  top: 68px;
  right: 16px;
}

[contenteditable=true]:empty:before {
  content:attr(data-ph);
  pointer-events: none;
  color:grey;
  font-style:italic;
  display: block;
}

.chat-messages {
  padding: 10px 16px 8px;
  margin: 16px auto 16px 0;
  display: flex;
  overflow-wrap: break-word;
}

// disputes

.card-dispute {
  margin-top: 14px;
  width: 100%;
  max-width: 388px;
}

.card-dispute .ant-card-body {
  padding: 14px 17px 0;
}

.sum-space-dispute .ant-space-item {
 height: 32px;
}